<template>
  <div class="page-body">
      <title-bar title="工单管理">
        <el-upload
            :action="this.globalConfig.apiRoot+'/requirement/upload'"
            with-credentials
            :show-file-list="false"
            accept=".xls,.xlsx"
            :on-success="onUploadSuccess"
            :on-error="onUploadError"
            :on-progress="()=>{loading = true}"
            style="margin-left: 10px">
          <el-button type="primary" icon="fa fa-upload" :loading="loading">
            工单导入
          </el-button>
        </el-upload>
      </title-bar>
      <el-form ref="queryForm" v-model="queryForm" inline label-width="80px">
        <div>
          <el-form-item label="流程名称">
            <code-select v-model="queryForm.flowName" code-type="flowName" @change="query" style="width: 150px"></code-select>
          </el-form-item>
          <el-form-item label="工单状态">
            <el-select v-model="queryForm.finished" clearable @change="query" style="width: 150px">
              <el-option :value="true" label="已完工"></el-option>
              <el-option :value="false" label="未完工"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工单日期">
            <el-date-picker v-model="dates" type="daterange" value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="客户编码">
            <el-input v-model.trim="queryForm.userCode" clearable placeholder="模糊匹配" style="width: 150px"/>
          </el-form-item>
          <el-form-item label="客户名称">
            <el-input v-model.trim="queryForm.userName" clearable placeholder="模糊匹配" style="width: 150px"/>
          </el-form-item>
          <el-form-item label="客户电话">
            <el-input v-model.trim="queryForm.userPhoneNum" clearable placeholder="模糊匹配" style="width: 150px"/>
          </el-form-item>
          <el-form-item label="地址">
            <el-input v-model.trim="queryForm.address" clearable placeholder="模糊匹配" style="width: 150px"/>
          </el-form-item>
          <el-form-item label="工单编号">
            <el-input v-model.trim="queryForm.requirementId" clearable placeholder="模糊匹配" style="width: 180px"/>
          </el-form-item>
          <el-form-item label-width="0px">
            <el-button type="primary" @click="query()" icon="fa fa-search" :loading="loading">
              查询
            </el-button>
            <el-button type="default" @click="reset()" :loading="loading">
              重置
            </el-button>
          </el-form-item>
        </div>
      </el-form>
      <el-table stripe :data="records" :border="true" v-loading="loading">
        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
        <el-table-column prop="requirementId" label="工单编号" min-width="100" align="center"/>
        <el-table-column prop="requirementTime" label="工单时间" min-width="110" align="center"/>
        <el-table-column prop="flowName" label="流程名称" align="center" min-width="90"/>
        <el-table-column prop="userCode" label="客户编号" align="center" min-width="70"/>
        <el-table-column prop="userName" label="客户名称" align="center" min-width="60"/>
        <el-table-column prop="userPhoneNum" label="客户电话号码" align="center" min-width="60"/>
        <el-table-column prop="address" label="客户地址" min-width="200"/>
        <el-table-column prop="workCount" label="施工单数" align="center" width="80"/>
        <el-table-column prop="finished" label="工单状态" align="center" width="80" v-slot="{row}">
          <span v-if="row.finished" class="color-success">已完工</span>
          <span v-else class="color-error">未完工</span>
        </el-table-column>
        <el-table-column prop="edit" label="操作" align="center" width="100" v-slot="{row}">
          <el-link type="primary" @click="finish(row, false)" v-if="row.finished">撤回</el-link>
          <el-link type="primary" @click="finish(row, true)" v-else>完成</el-link>
          <el-link type="danger" @click="del(row)">删除</el-link>
        </el-table-column>
      </el-table>
      <table-pagination :page-query="queryForm" :total="total" @change="loadData"></table-pagination>
    </div>
</template>

<script>
import BaseVue from "@/components/base/BaseVue";
import TitleBar from "@/components/base/TitleBar.vue";
import TablePagination from "@/components/base/TablePagination.vue";
import CodeSelect from "@/components/base/CodeSelect.vue";
import QRCode from "qrcode";
export default {
  components: {CodeSelect, TablePagination, TitleBar},
  extends: BaseVue,
  data() {
    return {
      records: [],
      dates: null,
      total: 0,
      queryForm: {
        startDate: null,
        endDate: null,
        requirementId: null,
        userCode: null,
        userName: null,
        userPhoneNum: null,
        address: null,
        flowName: null,
        state: null,
        page: 1,
        limit: 10
      }
    }
  },
  created() {
    this.query();
  },
  methods: {
    loadData() {
      this.loading = true;
      if(this.dates) {
        this.queryForm.startDate = this.dates[0];
        this.queryForm.endDate = this.dates[1];
      }else{
        this.queryForm.startDate = null;
        this.queryForm.endDate = null;
      }
      this.postForm("/requirement/list", this.queryForm, true).then((dr) => {
        this.records = dr.rows || [];
        this.total = dr.total || 0;
      }).finally(() => {
        this.loading = false;
      });
    },
    query() {
      this.queryForm.page = 1;
      this.loadData();
    },
    finish(row, finished) {
      let msg = finished ? "确定要标记为已完成吗？" : "确定要标记为未完成吗？";
      this.$confirm(msg, "提示", {
        type: "info"
      }).then(()=>{
        this.loading = true;
        this.postForm("/requirement/finish", {
          requirementId: row.requirementId,
          finished: finished
        }, true).then(dr=>{
          this.loadData();
        }).finally(dr=>{
          this.loading = false;
        });
      })
    },
    del(row){
      this.$confirm("确定要删除此工单吗？", "提示", {
        type: "warning"
      }).then(()=>{
        this.loading = true;
        this.postForm("/requirement/delete", {
          requirementId: row.requirementId
        }, true).then(dr=>{
          this.loadData();
        }).finally(dr=>{
          this.loading = false;
        });
      })
    },
    onUploadSuccess(dr) {
      this.loading = false;
      if(dr.success) {
        this.query();
      } else {
        this.$message.error(dr.msg || '导入失败');
      }
    },
    onUploadError(dr) {
      this.loading = false;
      this.$message.error(dr.msg || "导入文件失败");
    },
    reset() {
      this.queryForm = {
        startDate: null,
            endDate: null,
            requirementId: null,
            userCode: null,
            userName: null,
            address: null,
            flowName: null,
            state: null,
            page: 1,
            limit: 10
      };
      this.query();
    }
  }
}
</script>

<style scoped lang="scss">
</style>
